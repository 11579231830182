import { twMerge } from 'tailwind-merge';
import CheckBoxIcon from '@/icons/CheckBoxIcon';
import UncheckBoxIcon from '@/icons/UncheckBoxIcon';
import SemicheckBoxIcon from '@/icons/SemicheckBoxIcon';
import CircularProgress from '@mui/material/CircularProgress';
import { classes } from './utils';

export type CheckBoxProps<N extends string> = {
  isUpdating?: boolean;
  isChecked?: boolean;
  className?: string;
  name: N;
  onChange: (updates: Record<N, boolean>) => void;
};

export default function CheckBox<N extends string>({
  isUpdating,
  isChecked,
  className,
  name,
  onChange,
}: CheckBoxProps<N>) {
  const handleChange = (checkedStatus: boolean) => {
    onChange({ [name]: checkedStatus } as Record<N, boolean>);
  };

  if (isUpdating) {
    // @ts-ignore -- Mui not recognizing custom palette
    return <CircularProgress className={className} size={20} color="grey-5" />;
  }

  if (isChecked === undefined) {
    // Indeterminate state
    return (
      <SemicheckBoxIcon
        className={twMerge(classes.checkedIcon, className)}
        onClick={() => handleChange(true)}
      />
    );
  }

  return isChecked ? (
    <CheckBoxIcon
      className={twMerge(classes.checkedIcon, className)}
      onClick={() => handleChange(false)}
    />
  ) : (
    <UncheckBoxIcon
      className={twMerge(classes.uncheckedIcon, className)}
      onClick={() => handleChange(true)}
    />
  );
}
