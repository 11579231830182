import { twMerge } from 'tailwind-merge';
import {
  CardProps as MuiCardProps,
  default as MuiCard,
} from '@mui/material/Card';
import { classes } from './utils';

export interface CardProps extends MuiCardProps {}

export default function Card({
  className = '',
  raised = false,
  ...props
}: CardProps) {
  return (
    <MuiCard
      raised={raised}
      className={twMerge(classes.card, className)}
      {...props}
    />
  );
}
