import { useState } from 'react';

export function useToggle(initialValue = false) {
  const [value, setValue] = useState<boolean>(initialValue);

  const toggle = (bool?: boolean) => {
    if (typeof bool === 'boolean') setValue(bool);
    else setValue((val) => !val);
  };

  return [value, toggle] as const;
}
