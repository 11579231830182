import {
  cloneElement,
  DetailedHTMLProps,
  LiHTMLAttributes,
  ReactElement,
} from 'react';
import { twMerge } from 'tailwind-merge';
import ListItemText from '@mui/material/ListItemText';
import { classes } from './utils';

export interface MenuItemProps
  extends DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> {
  label: string;
  icon?: ReactElement;
}

export default function MenuItem({
  className = '',
  icon,
  label,
  ...props
}: MenuItemProps) {
  return (
    <li className={twMerge(classes.listItem, className)} {...props}>
      {icon
        ? cloneElement(icon, {
            color: icon.props.color || 'grey-7',
            fontSize: 'large',
            className: twMerge(classes.icon, icon.props.className || ''),
          })
        : null}
      <ListItemText>{label}</ListItemText>
    </li>
  );
}
