import { twMerge } from 'tailwind-merge';
import Divider, { DividerProps } from '@mui/material/Divider';
import { classes } from './utils';

export interface MenuDividerProps extends DividerProps {}

export default function MenuDivider({
  className = '',
  ...props
}: MenuDividerProps) {
  return <Divider className={twMerge(classes.divider, className)} {...props} />;
}
