import { theme } from '@/utils/theme';

export const classes = {
  button: 'rounded-lg',
  caps: 'capitalize',
  nonCaps: 'normal-case',
  containedBackground: 'bg-purple-2 hover:bg-purple-3',
  containedText: 'text-white',
  border: 'border',
  outlinedBorder: 'border-purple-2 hover:border-purple-3',
  outlinedText: 'text-purple-2',
  text: 'text-purple-2 hover:text-purple-3',
  disabled: 'not-allowed',
};

export const styles = {
  button: {
    border: '1px solid transparent',
    '&:focus': {
      border: `1px solid ${theme.palette['purple-3'].main}`,
    },
  },
};
