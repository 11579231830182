import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import MuiDialog from '@mui/material/Dialog';
import DialogTitle from './DialogTitle';
import DialogBody from './DialogBody';
import DialogActions from './DialogActions';
import DefaultDialogActions from './DefaultDialogActions';
import { classes } from './utils';
import { PaperProps } from '@mui/material';

export type { DefaultDialogActionsProps } from './DefaultDialogActions';

export interface DialogProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  onClose: () => void;
  paperProps?: PaperProps;
}

export default function Dialog({
  onClose,
  paperProps = {},
  className = '',
  ...props
}: DialogProps) {
  return (
    <MuiDialog
      open
      disableRestoreFocus
      maxWidth={false}
      PaperProps={{
        ...paperProps,
        className: twMerge(classes.paper, paperProps.className || ''),
      }}
      onClose={onClose}>
      <div className={twMerge(classes.body, className)} {...props} />
    </MuiDialog>
  );
}

export { DialogTitle, DialogBody, DialogActions, DefaultDialogActions };
