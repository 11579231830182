import type { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@/icons/CloseIcon';
import { classes } from './utils';

interface DialogTitleProps extends PropsWithChildren {
  title?: string;
  subtitle?: string;
  className?: string;
  onClose?: () => void;
}

export default function DialogTitle({
  title,
  subtitle,
  className = '',
  children,
  onClose,
  ...props
}: DialogTitleProps) {
  const renderTitles = () => {
    return (
      <>
        {title ? <h1 className={classes.title}>{title}</h1> : null}
        {subtitle ? <h2 className={classes.subtitle}>{subtitle}</h2> : null}
      </>
    );
  };
  if (!!onClose) {
    return (
      <div
        className={twMerge(
          classes.titles,
          classes.closeButtonWrapper,
          className
        )}
        {...props}>
        <div className={classes.closeButtonTitles}>{renderTitles()}</div>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
    );
  } else {
    return (
      <div className={twMerge(classes.titles, className)} {...props}>
        {renderTitles()}
      </div>
    );
  }
}
