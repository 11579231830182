export const MMDD = 'mm/dd/yyyy';
export const DDMM = 'dd/mm/yyyy';

export type DateFormat = 'mm/dd/yyyy' | 'dd/mm/yyyy';

export const DATE_FORMAT_PREFERENCE = {
  MMDD: {
    name: 'MMDD',
    label: 'mm/dd/yyyy',
    value: 'mm/dd/yyyy',
  },
  DDMM: {
    name: 'DDMM',
    label: 'dd/mm/yyyy',
    value: 'dd/mm/yyyy',
  },
} as const;
