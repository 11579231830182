import type { ReactElement } from 'react';
import MuiTooltip, {
  type TooltipProps as MuiTooltipProps,
} from '@mui/material/Tooltip';
import { styles } from './utils';

export interface TooltipProps {
  arrow?: boolean;
  open?: boolean;
  className?: string;
  title: string;
  enterDelay?: number;
  leaveDelay?: number;
  placement?: MuiTooltipProps['placement'];
  onOpen?: MuiTooltipProps['onOpen'];
  onClose?: MuiTooltipProps['onClose'];
  children: ReactElement;
}

export default function Tooltip({
  arrow = false,
  children,
  ...props
}: TooltipProps) {
  // NOTE: Wrapping children in a div to ensure MuiTooltip has a ref-able element to latch on to.
  return (
    <MuiTooltip
      arrow={arrow}
      slotProps={{ popper: { sx: styles.tooltip } }}
      {...props}>
      <div>{children}</div>
    </MuiTooltip>
  );
}
