import { MouseEvent } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  ButtonProps as MuiButtonProps,
  default as MuiButton,
} from '@mui/material/Button';
import { classes, styles } from './utils';

export interface ButtonProps extends MuiButtonProps {
  disabled?: boolean;
  elevated?: boolean;
  capitalized?: boolean;
  variant?: 'text' | 'contained' | 'outlined';
  backgroundClasses?: string;
  borderClasses?: string;
  textClasses?: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

export default function Button({
  variant = 'contained',
  elevated = false,
  capitalized = true,
  backgroundClasses,
  borderClasses,
  textClasses,
  className = '',
  style = {},
  ...props
}: ButtonProps) {
  let classList = [
    classes.button,
    capitalized ? classes.caps : classes.nonCaps,
  ];
  if (variant === 'contained') {
    classList.push(backgroundClasses || classes.containedBackground);
    classList.push(textClasses || classes.containedText);
  } else if (variant === 'outlined') {
    classList.push(classes.border);
    classList.push(borderClasses || classes.outlinedBorder);
    classList.push(textClasses || classes.outlinedText);
  } else if (variant === 'text') {
    classList.push(textClasses || classes.text);
  }
  return (
    <MuiButton
      variant={variant}
      disableElevation={!elevated}
      className={twMerge(...classList, className)}
      sx={{ ...styles.button, ...style }}
      {...props}
    />
  );
}
