import {
  default as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';

export interface TextFieldProps<N extends string>
  extends Omit<Partial<MuiTextFieldProps<'outlined'>>, 'onChange'> {
  error?: boolean;
  name: N;
  label: string;
  value: string;
  type?: 'text' | 'password' | 'email' | 'phone';
  onChange: (update: Record<N, string>) => void;
}

export default function TextField<N extends string>({
  name,
  type = 'text',
  onChange,
  ...props
}: TextFieldProps<N>) {
  return (
    <MuiTextField
      type={type}
      variant="outlined"
      // @ts-ignore -- MuiTextField not recognizing custom palette
      color="purple-2"
      onChange={(e) =>
        onChange({ [name]: e.target.value } as Record<N, string>)
      }
      {...props}
    />
  );
}
